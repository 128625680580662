import { LineType } from "react-terminal-ui";

export const school = [
  {
    value: "𝗨𝗻𝗶𝘃𝗲𝗿𝘀𝗶𝘁𝘆 𝗼𝗳 𝗧𝗼𝗿𝗼𝗻𝘁𝗼",
  },
  {
    value: "Bachelor of Science, Computer Science",
  },
  {
    value: `Specialist in Software Engineering, Co-op`,
  },
  {
    value: `3.44/4.0 cumulative GPA`,
  },
];

export const skills = [
  {
    value:
      "• 𝗟𝗮𝗻𝗴𝘂𝗮𝗴𝗲𝘀:  Python, Java, C, C# (EF), SQL, NoSQL (MongoDB), Redis, HTML, CSS, TypeScript, JavaScript, GraphQL",
  },
  {
    value: "• 𝗟𝗶𝗯𝗿𝗮𝗿𝗶𝗲𝘀 : React, Angular, Vue, Express, Node, Redux",
  },
  {
    value: `• 𝗗𝗲𝘃𝗲𝗹𝗼𝗽𝗺𝗲𝗻𝘁 𝗧𝗼𝗼𝗹𝘀 / 𝗠𝗲𝘁𝗵𝗼𝗱𝗼𝗹𝗼𝗴𝗶𝗲𝘀 / 𝗖𝗹𝗼𝘂𝗱: Git, Docker, Jira, Agile, AWS (Lambda/S3)`,
  },
  {
    value: `• 𝗘𝗧𝗟 : SSIS`,
  },
];

export const work = [
  {
    type: LineType.Output,
    value:
      "𝗛𝗼𝗺𝗲𝗫 (Toronto, Ontario, May 2021-August 2021, Software Engineer Intern)",
  },
  {
    value:
      "Python,C# (dotnet, EF), AWS (Lambda, S3, ECR), React, TypeScript, GraphQL, Docker, Git ",
  },
  {
    value: "",
  },
  {
    value: `• Developed GraphQL BFF solution to facilitate communication from Mobile App and Website to backend
    microservices, significantly cutting down size of codebase `,
  },
  {
    value: "",
  },
  {
    value: `• Developed full stack microservice for video, implementing a video-on-demand service that streams video from
    S3 bucket and plays using a custom frontend video player`,
  },
  {
    value: "",
  },
  {
    value: `𝗕𝗼𝗺𝗯𝗮𝗿𝗱𝗶𝗲𝗿 𝗔𝗲𝗿𝗼𝘀𝗽𝗮𝗰𝗲
    (Toronto, Ontario, (Jan 2019-Dec 2019), Full Stack Developer Intern)`,
  },
  {
    value: "Angular, C# (dotnet, EF), SQL Server, SSIS, Git, TypeScript",
  },
  {
    value: "",
  },
  {
    value:
      "• Developed two web applications with upwards of 5000 monthly active users for security, tooling, and business  technology departments",
  },
  {
    value: "",
  },
  {
    value:
      "• Created scheduling application for tooling department that allows users to request/fulfill tooling services and manage usage of laser shooting machine, resulting in an 8% KPI increase",
  },
  {
    value: "",
  },

  {
    value: `• Replaced outdated paper and pencil contractor check-in processes with a modern, mobile-first website that allows users to bring in visitors/contractors, 
    automatically generates parking passes, and sends email reminders`,
  },
  {
    value: "",
  },
  {
    value: `• Developed and maintained 30+ SSIS packages for a Data warehouse to consolidate datasets from a variety of
      sources and display mass amounts of KPI and engineering data for several aircraft programs`,
  },
];

export const startingText = [
  {
    type: LineType.Output,
    value: "Welcome. Thanks for Visiting.   ",
  },
  {
    value:
      "If you'd like to see a more traditional website instead of using a terminal, type 'classic' ",
  },
  {
    value: "",
  },
  {
    value:
      "To view my work experience, personal projects, or technical skills  use the 'work', 'projects', or 'skills' commands ",
  },
  {
    value: "",
  },
  {
    value: "To send me an email, type 'email' ",
  },
  {
    value: "",
  },
  {
    value: "To see these options again, type 'help'",
  },
];

export const projects = [
  {
    type: LineType.Output,
    value: "𝗬𝗼𝘂𝗧𝘂𝗯𝗲 𝗣𝗮𝗿𝘁𝘆 (February 2021- April 2021)",
  },
  {
    value: "github.com/TonyAttalla/Youtube-Party",
  },
  {
    value: "React, TypeScript, Express, MongoDB, Redis, Git, Docker",
  },
  {
    value: "",
  },
  {
    value:
      "• Application that allows multiple users to synchronize YouTube video playback",
  },
  {
    value:
      "• Supports creation of rooms with unique ID’s, user authentication, and an easy-to-use UI",
  },
  {
    value: `• Wrote high performance web socket code, using a new approach to video synchronization optimized for slower
    connections`,
  },
  {
    value: "",
  },
  {
    type: LineType.Output,
    value: "𝗦𝗣𝗢𝗥𝗧𝗖𝗥𝗘𝗗 (September 2020- December 2020)",
  },
  {
    value: "𝗴𝗶𝘁𝗵𝘂𝗯.𝗰𝗼𝗺/𝗧𝗼𝗻𝘆𝗔𝘁𝘁𝗮𝗹𝗹𝗮/𝗦𝗽𝗼𝗿𝘁𝗰𝗿𝗲𝗱",
  },
  {
    value: "React, JavaScript, Express, MongoDB, Git, Jira",
  },
  {
    value: "",
  },
  {
    value: `• Application developed with a professional basketball player that acts as a hub for sports analysts to test their
      knowledge and compete against other users`,
  },
  {
    value: `•Performed full stack development duties as part of an Agile team of developers, consistently met sprint
      deadlines and pushed up high-quality deliverables`,
  },
  {
    value: `• Acted as representative for the team, meeting with product owners on a biweekly basis to demo new features
    and incorporate feedback`,
  },
];
export const help = [
  {
    value:
      "If you'd like to see a more traditional website instead of using a terminal, type 'classic' ",
  },
  {
    value:
      "To view my work experience, personal projects, or technical skills  use the 'work', 'projects', or 'skills' commands ",
  },
  {
    value: "To see these options again, type 'help'",
  },
];

export const notFound = [{ value: "command not found" }];
