import * as outputs from "./outputs";

function Parse(input) {
  switch (input.toLowerCase()) {
    case "help":
      return outputs.help;

    case "skills":
      return outputs.skills;

    case "classic":
      window.open("https://classic.tonyattalla.com", "_blank");
      return "";

    case "work":
      return outputs.work;

    case "education":
      return outputs.school;
    case "projects":
      return outputs.projects;

    default:
      return outputs.notFound;

    // code block
  }
}
export default Parse;
