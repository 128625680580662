import Terminal, { ColorMode, LineType } from "react-terminal-ui";
import { Box, useDisclosure, Button } from "@chakra-ui/react";
import Parse from "./parse";
import { useState, useRef } from "react";
import "./terminal.css";
import EmailModal from "./EmailModal";
import * as outputs from "./outputs";

function TerminalController() {
  const inputEl = useRef(null);
  const [terminalLineData, setTerminalLineData] = useState(
    outputs.startingText
  );
  const [modalOpen, setModalOpen] = useState(false);

  function getOutput(terminalInput) {
    if (terminalInput === "email") {
      // inputEl.current.click();
      setModalOpen(true);
    } else {
      let newOutput = Parse(terminalInput);

      const concatenated = terminalLineData
        .concat({
          type: LineType.Input,
          value: terminalInput,
        })
        .concat(newOutput);
      setTerminalLineData(concatenated);
    }
  }
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Box
        className="testBox"
        display="flex"
        justifyContent="center"
        alignItems="center"
        // height="100%"
        width="100%"
      >
        <Box width="90%" height="100%">
          <Terminal
            className="testBox"
            name="tony@tonyspc:~"
            colorMode={ColorMode.Dark}
            lineData={terminalLineData}
            onInput={(terminalInput) => getOutput(terminalInput)}
          />
          <EmailModal
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
          ></EmailModal>
        </Box>
      </Box>
    </Box>
  );
}
export default TerminalController;
