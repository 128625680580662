import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Textarea,
  Flex,
} from "@chakra-ui/react";
import emailjs from "emailjs-com";

import { FormControl, FormLabel } from "@chakra-ui/react";

import { useState } from "react";
function EmailModal({ isOpen, onClose }) {
  const [timesMounted, setTimesMounted] = useState(0);

  function sendEmail(e) {
    e.preventDefault();
    console.log("sending form");
    //emailjs.init("user_H87rAlwwNQEBkw8DMtwmS");
    emailjs
      .sendForm(
        "contact_service",
        "contact_form",
        e.target,
        "user_H87rAlwwNQEBkw8DMtwmS"
      )
      .then(
        (result) => {
          onClose();
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <Modal
      onClose={() => {
        console.log(timesMounted);
        console.log("on close firing");
        if (timesMounted === 0) {
          setTimesMounted(timesMounted + 1);
        } else {
          setTimesMounted(0);
          onClose();
        }
      }}
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Contact</ModalHeader>

        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={(e) => sendEmail(e)}>
            <Flex
              direction="column"
              justifyContent="space-evenly"
              alignItems="space-evenly
              "
            >
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  required
                  borderColor="black"
                  name="user_name"
                  placeholder="Your name"
                />
              </FormControl>

              <FormControl mt="5">
                <FormLabel>Email</FormLabel>
                <Input
                  required
                  borderColor="black"
                  type="email"
                  name="user_email"
                  placeholder="Your email"
                />
              </FormControl>

              <FormControl mt="5" mb="2">
                <FormLabel>Message</FormLabel>

                <Textarea
                  required
                  borderColor="black"
                  placeholder="Your message"
                  name="message"
                />
              </FormControl>
              <Button
                mb="5"
                colorScheme="green"
                width="full"
                mt={4}
                type="submit"
              >
                Send
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
export default EmailModal;
