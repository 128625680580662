import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import TerminalController from "./components/Terminal";

function App() {
  return (
    <ChakraProvider>
      <TerminalController />
    </ChakraProvider>
  );
}

export default App;
